<template>
  <section>
    <v-navigation-drawer permanent app class="elevation-5 navigation-drawer" width="230">
      <v-list-item class="primary">
        <v-avatar color="secondary">
          {{ $store.state.user.firstName.charAt(0) }}
          {{ $store.state.user.lastName.charAt(0) }}
        </v-avatar>
        <v-list-item-content class="ml-2">
          <v-list-item-title class="text-h6 white--text">
            {{ $store.state.user.fullName }}
          </v-list-item-title>
          <v-list-item-subtitle class="white--text">
            {{ $store.state.user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <span v-for="route in navigation" :key="route.name">
        <span v-if="route.meta.requiredRoles.includes(role)">
          <v-list-item link :to="{ name: route.name }" color="primary">
            <v-list-item-content class="ml-2">
              <v-list-item-title class="text-h6">
                <font-awesome-icon size="sm" :icon="['fas', route.meta.icon]" fixed-width></font-awesome-icon>
                <span class="ml-2 font-weight-regular">{{ route.meta.navbarTitle }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </span>
      </span>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="primary" block :to="{ name: 'home' }">
            <font-awesome-icon size="1x" :icon="['fas', 'globe']"></font-awesome-icon>
            <span class="ml-2">Sea2Sea | home</span>
          </v-btn>
        </div>
        <div class="pa-2">
          <v-btn block @click="doLogout">
            <font-awesome-icon size="1x" :icon="['fas', 'arrow-left']"></font-awesome-icon>
            <span class="ml-2">Logout</span>
          </v-btn>
        </div>
        <div class="text-center mb-2">
          <div class="caption">
            Copyright © Unixity LLC
          </div>
          <div class="caption">
            Created by <a @click="doOpenExternalUrl('https://unixity.co/about/matthew')">Matthew Maggio</a>
          </div>
          <div class="caption">
            Version {{ applicationVersion }}
          </div>
        </div>
      </template>
</v-navigation-drawer>

    <div class="mt-2"></div>
    <router-view></router-view>

    <v-card v-if="!forceThrough" class="hidden-md-and-up fullscreen" style="position: fixed; width: 100%; height: 100%; top:0; left:0; z-index: 10000;">
      <v-card-text class="text-center">
        <v-container>
          <v-avatar width="100" tile>
            <v-img src="https://s3.us-east-1.wasabisys.com/sea2sea/media/Logo-White-Text.png"></v-img>
          </v-avatar>
          <div class="friendly-1 mt-4">
            The admin section should not be used on smaller screen devices. Please use a larger screen.
            <div class="mt-2">
              You can allow your device through but some elements may not work properly
            </div>
          </div>
          <v-btn block @click="forceThrough = true" class="mt-10" color="warning">
            <font-awesome-icon size="1x" :icon="['fas', 'wand-magic-sparkles']"></font-awesome-icon>
            <span class="ml-2">Allow my device for now.</span>
          </v-btn>
          <v-btn block @click="doLogout" class="mt-10">
            <font-awesome-icon size="1x" :icon="['fas', 'arrow-left']"></font-awesome-icon>
            <span class="ml-2">Logout</span>
          </v-btn>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showWelcome" persistent max-width="1200px">
      <welcome-tutorial @complete="showWelcome = false"></welcome-tutorial>
    </v-dialog>

    <reset-password ref="resetPassword"></reset-password>

  </section>
</template>

<script>
import WelcomeTutorial from '@/components/admin/welcomeTutorial.vue'
import ResetPassword from '@/components/admin/resetPassword.vue'

export default {
  components: { ResetPassword, WelcomeTutorial },
  data: () => ({
    navigation: [],
    forceThrough: false,
    role: '',
    showWelcome: true,
    resetPassword: false,
    mobile: false
  }),
  methods: {
    doLogout () {
      const electron = !(navigator.userAgent.toLowerCase().indexOf(' electron/') === -1)

      this.$router.push({ name: electron ? 'signin' : 'home' }).then(_ => {
        this.$nextTick(() => {
          this.$store.commit('doResetUser')
        })
      })
    }
  },
  mounted () {
    this.role = this.$store.getters.doGetRole
    this.showWelcome = this.$store.getters.doGetFirstSignIn
    this.resetPassword = this.$store.getters.doGetTempPassword

    this.$nextTick(() => {
      this.navigation = this.$router.getRoutes().filter(route => route.meta.adminNavbar !== undefined ? route.meta.adminNavbar : false)

      if (this.resetPassword) {
        this.$refs.resetPassword.doOpen()
      }
    })
  }
}
</script>

<style scoped>
.navigation-drawer {
  background-color: var(--v-background-base) !important;
}
</style>
